import type { FormHeadingTranslationObj } from "../../translation-keys/form-heading-text-keys.js";

const genericHeading = "Remplissez ce formulaire pour télécharger les ressources complémentaires.";
const FormHeading: FormHeadingTranslationObj={
  "document-Heading": genericHeading,
  "document-landing-page-Heading": genericHeading,
  "post-event-assets-Heading": "",
  "ppc-Heading": genericHeading,
  "case-study-Heading": genericHeading,
  "video-Heading": genericHeading,
  "video-landing-page-Heading": genericHeading,
  "video-gated-Heading": genericHeading,
  "events-registration-Heading": "Register by filling out the form below.",
  "resource-forms-Heading": genericHeading,
  "contact-Heading": "Dites-nous en plus sur vous pour que nous puissions répondre à vos problématiques logicielles, de qualité et de conformité.",
  "qx-roi-Heading": "Schedule Your ROI Assessment Today",
  "mx-roi-Heading": "Schedule Your ROI Assessment Today"
};
    
export default FormHeading;